import useMediaQuery from "@mui/material/useMediaQuery";
import { isNotNullOrUndefined, isNullOrUndefined } from "@xxl/common-utils";
import type { ProductListingSmallBannerData } from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import type { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import { useStateValue } from "cotton-box-react";
import random from "lodash/random";
import React, { forwardRef, useEffect, useRef } from "react";
import { useSessionSource } from "../../contexts/Session";
import { useSharedData } from "../../contexts/SharedData";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { ProductMetaData } from "../../global";
import {
  mobileAndTabletNotHorizontalMediaQuery,
  mobileMediaQuery,
} from "../../utils/xxl-screen";
import { Product } from "../Product/Product";
import {
  getPriceData,
  getProductType,
  toProductCardData,
  type CombinedProductData,
} from "../Product/product-helper";
import { ProductListingSmallBanner } from "../ProductListingSmallBanner/ProductListingSmallBanner";
import { SET_HIDDEN_PRODUCT_ITEMS_NUMBER } from "../Search/Constants";
import { useSearchContext } from "../Search/SearchState";
import { ListWrapper } from "./ProductList.styled";
import {
  getCampaignCarousel,
  getOneCardContentComponent,
  getProductsRecommendationsCarousel,
} from "./ProductListGridExtras";
import { getProductListPrice } from "./ProductListPrice";
import {
  CAMPAIGN_CAROUSEL_KEY,
  NR_OF_PRODUCTS_BETWEEN_CATEGORY_CONTENT,
  RECOMMENDATIONS_CAROUSEL_KEY,
} from "./constants";
import { useGridExtras } from "./useGridExtras";

type ProductListProps = {
  products: CombinedProductData[];
  smallBanners: ProductListingSmallBannerData[];
  additionalClasses?: string;
  style?: React.CSSProperties;
  isPersonalized?: boolean;
  selectedColumnsNumber: number;
};

export const calculateCategoryContentPosition = (
  products: CombinedProductData[]
): number => {
  const nrOfProductsBetweenCategoryContent =
    NR_OF_PRODUCTS_BETWEEN_CATEGORY_CONTENT;
  const hasFewProducts = nrOfProductsBetweenCategoryContent > products.length;

  return hasFewProducts ? products.length : nrOfProductsBetweenCategoryContent;
};

export const getSmallBanners = (
  smallBanners: (ProductListingSmallBannerData | undefined)[],
  productIndex: number,
  contentIndexRef: React.MutableRefObject<number>,
  nrOfProductsBetweenCategoryContent: number
): JSX.Element | null => {
  const showContentAtThisPosition =
    (productIndex % nrOfProductsBetweenCategoryContent) + 2 ===
    nrOfProductsBetweenCategoryContent - 2;
  const hasRemainingContent = contentIndexRef.current < smallBanners.length;
  if (hasRemainingContent && showContentAtThisPosition) {
    const content = smallBanners[contentIndexRef.current];
    contentIndexRef.current += 1;

    return content !== undefined ? (
      <ProductListingSmallBanner
        key={content.id ?? `${productIndex}-${contentIndexRef.current}`}
        content={content}
        index={contentIndexRef.current}
      />
    ) : null;
  }

  return null;
};

const ProductList = forwardRef<HTMLUListElement, ProductListProps>(
  (
    {
      products,
      smallBanners,
      isPersonalized = true,
      selectedColumnsNumber: nrOfProductsInRow,
    },
    ref
  ) => {
    const { state, dispatch } = useSearchContext();
    const { t } = useTranslations();
    const {
      featureToggles: { toggle_products_as_package_quantity },
      pageType,
      siteDefaultLanguage,
    } = useSharedData().data;
    const isLoggedIn = useStateValue(useSessionSource);
    const {
      fetchMoreProductsCount,
      isCampaignPage,
      page,
      pageSize,
      togglePlpOneCardContentComponent,
      relativePageUrl,
      searchData,
      isFetchingNewSearchResult,
    } = state;
    const showHighlightedLabel = !isCampaignPage;
    const hasSmallBanners = smallBanners.length > 0;
    const nrOfProductsBetweenCategoryContent =
      calculateCategoryContentPosition(products);
    const maxNrOfSmallBannersPerPage =
      products.length / nrOfProductsBetweenCategoryContent;

    const nrOfPages = Math.ceil((searchData?.results?.count ?? 0) / pageSize);
    const { personalizedData, gridExtrasData } = useGridExtras(
      relativePageUrl,
      nrOfPages,
      togglePlpOneCardContentComponent
    );

    const isMobile = useMediaQuery(mobileMediaQuery);
    const isTablet = useMediaQuery(mobileAndTabletNotHorizontalMediaQuery);
    const isDesktop = !isMobile && !isTablet;

    const smallBannersIndexRef = useRef(0);
    smallBannersIndexRef.current = 0;

    const recommendedProducts = personalizedData?.find(
      ({ key }) => key === RECOMMENDATIONS_CAROUSEL_KEY
    )?.productList;
    const recommendedProductsPerCarousel =
      gridExtrasData?.productsRecommendationsCarousel?.productsCount ?? 0;

    const maxNumberOfRecommendationsCarousel = Math.ceil(
      (recommendedProducts?.length ?? 0) / recommendedProductsPerCarousel
    );
    const hasProductsRecommendationCarousel =
      (recommendedProducts?.length ?? 0) > 0;
    const recommendationsRef = useRef(0);
    recommendationsRef.current = 0;
    const campaignProducts = personalizedData?.find(
      ({ key }) => key === CAMPAIGN_CAROUSEL_KEY
    )?.productList;

    const campaignCarouselRef = useRef(0);
    campaignCarouselRef.current = 0;
    const hasCampaignCarousel = (campaignProducts?.length ?? 0) > 0;

    const spaceForHalfGridCarousel = isMobile
      ? nrOfProductsInRow
      : nrOfProductsInRow / 2;

    const currentPage = page + 1;

    const oneCardContentComponentsRef = useRef(0);
    oneCardContentComponentsRef.current = 0;
    const nrOfOneCardContentComponents =
      gridExtrasData?.oneCardContentComponents.length ?? 0;
    const hasOneCardContentComponents =
      togglePlpOneCardContentComponent && nrOfOneCardContentComponents > 0;

    const bannersOnCurrentPage =
      maxNrOfSmallBannersPerPage * currentPage < smallBanners.length
        ? maxNrOfSmallBannersPerPage
        : smallBanners.length;

    const numberOfCards =
      products.length +
      bannersOnCurrentPage +
      (hasOneCardContentComponents ? nrOfOneCardContentComponents : 0) +
      (hasProductsRecommendationCarousel
        ? Math.min(currentPage, maxNumberOfRecommendationsCarousel) *
          spaceForHalfGridCarousel
        : 0) +
      (hasCampaignCarousel ? spaceForHalfGridCarousel : 0);

    const fullRows = Math.floor(numberOfCards / nrOfProductsInRow);

    const bannersToHide =
      fetchMoreProductsCount !== 0
        ? numberOfCards - fullRows * nrOfProductsInRow
        : 0;

    // we don't want to show campaign carousel in the first 3 rows.
    const startPoint = nrOfProductsInRow * 3;
    const randomPosition = random(
      startPoint,
      pageSize - spaceForHalfGridCarousel
    );

    const positionForCampaignCarousel =
      randomPosition % spaceForHalfGridCarousel === 0
        ? randomPosition
        : randomPosition - (randomPosition % spaceForHalfGridCarousel);

    useEffect(() => {
      dispatch({
        type: SET_HIDDEN_PRODUCT_ITEMS_NUMBER,
        payload: bannersToHide,
      });
    }, [bannersToHide, dispatch]);

    return (
      <ListWrapper
        columnAmount={nrOfProductsInRow}
        data-testid="product-list"
        isLoading={isFetchingNewSearchResult}
        ref={ref}
      >
        {products.map((product, index) => {
          let bannerPosition = nrOfProductsBetweenCategoryContent;
          const hasMoreProducts = Boolean(
            products.length > nrOfProductsBetweenCategoryContent
          );
          const hasMoreProductsSections = Boolean(
            products.length / nrOfProductsBetweenCategoryContent >
              smallBanners.length
          );
          const isSectionWithoutSmallBanner =
            index + 1 >
              smallBanners.length * nrOfProductsBetweenCategoryContent &&
            bannerPosition === nrOfProductsBetweenCategoryContent;
          if (
            hasMoreProducts &&
            hasMoreProductsSections &&
            isSectionWithoutSmallBanner
          ) {
            bannerPosition += 1;
          }

          const hideProduct =
            fetchMoreProductsCount !== 0 &&
            (smallBanners.length > 0 ||
              hasProductsRecommendationCarousel ||
              hasCampaignCarousel ||
              hasOneCardContentComponents) &&
            products.length +
              (smallBanners.length > 0
                ? bannersOnCurrentPage % nrOfProductsInRow
                : 0) +
              (hasOneCardContentComponents
                ? nrOfOneCardContentComponents % nrOfProductsInRow
                : 0) +
              (hasProductsRecommendationCarousel
                ? (Math.min(currentPage, maxNumberOfRecommendationsCarousel) *
                    spaceForHalfGridCarousel) %
                  nrOfProductsInRow
                : 0) +
              (hasCampaignCarousel
                ? spaceForHalfGridCarousel % nrOfProductsInRow
                : 0) !==
              0 &&
            index >= products.length - bannersToHide;

          const productMetaData: ProductMetaData = {
            list: "search",
            pageType,
            position: index,
          };

          const productType = getProductType(product);
          const units = "units" in product ? product.units : undefined;
          const { code, priceDisplay } = product;

          if (isNullOrUndefined(priceDisplay)) {
            log.error(
              `Product with code ${code ?? "undefined"} is missing price display.`
            );
            return null;
          }

          const priceData = getPriceData({
            version: 1,
            priceDisplay,
            productType,
            showPackagePrice: false,
            siteDefaultLanguage,
            toggleProductsAsPackageQuantity:
              toggle_products_as_package_quantity,
            units,
          });

          if (priceData === null) {
            log.error(
              `Product with code ${code ?? "undefined"} is missing price data.`
            );
            return null;
          }

          const { colorTheme, highlightedLabel, priceSplash } = priceData;

          return (
            <React.Fragment key={product.code}>
              <li>
                <Product
                  colorTheme={colorTheme}
                  hidden={Boolean(hideProduct)}
                  highlightedLabel={highlightedLabel}
                  isHoverable={isDesktop}
                  PriceComponent={getProductListPrice({
                    product,
                    selectedColumnsNumber: nrOfProductsInRow,
                    t,
                  })}
                  product={toProductCardData({
                    product,
                    type:
                      "productType" in product ? product.productType : "NORMAL",
                    t,
                    isLoggedIn,
                  })}
                  priceSplash={priceSplash}
                  productMetaData={productMetaData}
                  selectedColumnsNumber={nrOfProductsInRow}
                  selectedFilters={state.selectedFilters}
                  showHighlightedLabel={showHighlightedLabel}
                  prioritizeImageLoad={true}
                  positionInList={index}
                />
              </li>
              {hasSmallBanners
                ? getSmallBanners(
                    smallBanners,
                    index,
                    smallBannersIndexRef,
                    bannerPosition
                  )
                : null}
              {isNotNullOrUndefined(
                gridExtrasData?.productsRecommendationsCarousel
              ) && recommendedProducts !== undefined
                ? getProductsRecommendationsCarousel({
                    carouselType: gridExtrasData.productsRecommendationsCarousel
                      .strategy as RecommendationsParametersStrategyEnum,
                    contentIndexRef: recommendationsRef,
                    currentPage: page,
                    currentProductIndex: index,
                    isLoading: isFetchingNewSearchResult,
                    isMobile,
                    nrOfOneCardContentComponents:
                      oneCardContentComponentsRef.current,
                    nrOfProductsInRow,
                    numberOfCampaignCarousels: campaignCarouselRef.current,
                    numberOfSmallBanners: smallBannersIndexRef.current,
                    pageType,
                    PriceComponents: recommendedProducts.map(
                      (recommendedProduct) =>
                        getProductListPrice({
                          product: recommendedProduct,
                          selectedColumnsNumber: nrOfProductsInRow,
                          t,
                        })
                    ),
                    productsPerCarousel: recommendedProductsPerCarousel,
                    productsPerPage: pageSize,
                    recommendedProducts,
                    selectedColumnsNumber: nrOfProductsInRow,
                    slidersPerView: spaceForHalfGridCarousel,
                    title: gridExtrasData.productsRecommendationsCarousel.title,
                  })
                : null}
              {hasCampaignCarousel &&
              isNotNullOrUndefined(campaignProducts) &&
              isPersonalized &&
              gridExtrasData?.campaignCarousel !== undefined
                ? getCampaignCarousel({
                    campaignCarouselProductsData:
                      gridExtrasData.campaignCarousel,
                    carouselData: gridExtrasData.campaignCarousel,
                    contentIndexRef: campaignCarouselRef,
                    currentProductIndex: index,
                    isMobile,
                    nrOfProductsInRow,
                    numberOfRecommendationsCarousels:
                      recommendationsRef.current,
                    numberOfSmallBanners: smallBannersIndexRef.current,
                    nrOfOneCardContentComponents:
                      oneCardContentComponentsRef.current,
                    pageType,
                    positionForCampaignCarousel,
                    PriceComponents: campaignProducts.map((campaignProduct) =>
                      getProductListPrice({
                        product: campaignProduct,
                        selectedColumnsNumber: nrOfProductsInRow,
                        t,
                      })
                    ),
                    products: campaignProducts,
                    selectedColumnsNumber: nrOfProductsInRow,
                    slidesPerView: isMobile ? 1 : spaceForHalfGridCarousel,
                  })
                : null}
              {hasOneCardContentComponents
                ? getOneCardContentComponent(
                    gridExtrasData?.oneCardContentComponents ?? [],
                    index,
                    oneCardContentComponentsRef,
                    smallBannersIndexRef.current,
                    nrOfProductsInRow
                  )
                : null}
            </React.Fragment>
          );
        })}
      </ListWrapper>
    );
  }
);

ProductList.displayName = "ProductList";

export { ProductList };
