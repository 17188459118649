import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ, typographyToCss } from "../../styles/helpers";
import spaces from "../../styles/theme/spaces";
import { GRID_COLUMNS } from "../Filter/FilterBar/constants";
import typography from "./ProductListGridExtras.typography";
import type { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import { productCarouselColorTheme } from "../PersonalizedProductList/personalized-product-list-helper";
import { fourGridMargin, sixGridMargin } from "./ProductList.styled";
import { hexToRGB } from "../../utils/xxl-hex-to-rgb";
import { xxlTheme } from "../../styles/xxl-theme";

const { colors } = xxlTheme;

type ListWrapperProps = {
  columnAmount: number;
  carouselType: RecommendationsParametersStrategyEnum;
};

export const RecommendationsWrapper = styled.li<ListWrapperProps>(
  ({ columnAmount, carouselType }) => css`
    display: flex;
    flex-direction: column;
    justify-content: start;
    grid-column: 1 / span ${columnAmount};
    ${productCarouselColorTheme(carouselType)}
    margin: 0 -${spaces.smallRegular};

    ${MQ("tablet")} {
      margin: 0;
      height: 100%;
      padding: 0;
      grid-column: ${columnAmount / 2 + 1} / ${columnAmount + 1};
    }

    ${MQ("tabletHorizontal")} {
      ${columnAmount === GRID_COLUMNS.four
        ? `li:nth-of-type(n+5) {
            height: calc(100% - ${fourGridMargin});
          }`
        : `li:nth-of-type(n+7) {
            height: calc(100% - ${sixGridMargin});
          }`};
    }
  `
);

type CampaignCarouselProps = {
  productIndex: number;
} & ListWrapperProps;

export const CampaignCarouselWrapper = styled.li<CampaignCarouselProps>(
  ({ columnAmount, productIndex }) => css`
    height: 180px;
    grid-column: 1 / span ${columnAmount};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    background-color: ${colors.xxlLightGrey};

    ${MQ("tablet")} {
      background-color: ${hexToRGB(colors.xxlLightGrey, 0.4)};
      height: 100%;
      justify-content: start;
      flex-direction: column;
      grid-column: ${productIndex % columnAmount === 0
          ? 1
          : columnAmount / 2 + 1} /
        ${productIndex % columnAmount === 0
          ? columnAmount / 2 + 1
          : columnAmount + 1};
    }

    ${MQ("tabletHorizontal")} {
      ${columnAmount === GRID_COLUMNS.four
        ? `li:nth-of-type(n+5) {
            height: calc(100% - ${fourGridMargin});
          }`
        : `li:nth-of-type(n+7) {
            height: calc(100% - ${sixGridMargin});
          }`};
    }
  `
);

export const Title = styled.span`
  ${typographyToCss(typography.title)};
  text-align: left;
  margin: 0;
  padding: ${spaces.smallRegular};

  ${MQ("tablet")} {
    padding: ${spaces.large} ${spaces.smallLarge};
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 0 ${spaces.smallRegular};

  ${MQ("tablet")} {
    padding: 0 ${spaces.smallLarge};
  }

  .slick-slider {
    flex: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3px;

    ${MQ("tabletHorizontal")} {
      margin-bottom: calc(
        ${spaces.large} + 3px
      ); // 3px is an indicator's thickness
    }
  }

  div:first-of-type {
    flex: 0;
  }
`;

export const ImageCarouselContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  justify-content: space-between;
`;

export const MobileImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  height: 180px;
  background-color: ${colors.xxlLightGrey};

  figure {
    display: block;
    align-self: center;
    overflow: hidden;
    text-align: center;
    overflow: hidden;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
`;

type TextProps = {
  color?: string;
};

type HeadlineProps = {
  columnAmount: number;
} & TextProps;

export const Headline = styled.span<HeadlineProps>(
  ({ color = colors.xxlWebBlack, columnAmount }) => css`
    ${typographyToCss(typography.title)}
    color: ${color};
    text-align: left;
    padding: ${spaces.smallRegular};
    margin: 0;

    ${MQ("tabletHorizontal")} {
      padding-top: ${spaces.mini};
      ${columnAmount === GRID_COLUMNS.six &&
      `&:after {
        content: "";
        display: block;
        width: 1px;
        height: calc(100% - 2 * ${spaces.large});
        background-color: ${colors.xxlWhite};
        position: absolute;
        top: ${spaces.large};
        left: 50%;
      }`}
    }
  `
);

type HeadingWrapperProps = {
  desktopImageUrl?: string;
  mobileImageUrl?: string;
};

export const HeadingWrapper = styled.div<HeadingWrapperProps>(
  ({ desktopImageUrl, mobileImageUrl }) => css`
    display: flex;
    position: relative;
    height: 100%;
    width: 50%;
    background-position: center;
    background-image: url(${mobileImageUrl ?? ""});
    background-size: cover;
    flex-direction: column;
    justify-content: space-between;

    ${MQ("tablet")} {
      flex-direction: row;
      width: 100%;
      min-height: 117px;
      background-image: url(${desktopImageUrl ?? ""});
      margin-bottom: ${spaces.mini};
    }
  `
);

export const HeadingMainInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MQ("tablet")} {
    width: 50%;
  }
`;

export const HeadingInfoWrapper = styled(HeadingMainInfoWrapper)<HeadlineProps>(
  ({ columnAmount }) => css`
    align-items: flex-start;
    justify-content: ${columnAmount === GRID_COLUMNS.six
      ? `space-between`
      : `flex-end`};
    padding: ${spaces.smallRegular};

    ${MQ("tablet")} {
      align-items: flex-end;
    }
  `
);

export const Information = styled.p<TextProps>(
  ({ color = "black" }) => css`
    color: ${color};
    text-align: right;
    margin: 0;
  `
);

type LabelBoxProps = {
  backgroundColor?: string;
} & TextProps;

export const LabelBox = styled.div<LabelBoxProps>(
  ({ color = "black", backgroundColor = "white" }) => css`
    width: fit-content;
    color: ${color};
    background-color: ${backgroundColor};
    padding: ${spaces.miniMicro} ${spaces.mini};
    margin: ${spaces.smallRegular} 0 0 ${spaces.smallRegular};
    font-size: ${spaces.smallRegular};

    ${MQ("tabletHorizontal")} {
      font-size: ${spaces.small};
    }
  `
);

export const Link = styled.a<TextProps>(
  ({ color = "black" }) => css`
    color: ${color};
    ${typographyToCss(typography.link)};
    display: flex;
    align-items: center;
    gap: ${spaces.mini};
  `
);
